import {
  EventType,
  isExternal,
  isRsvp,
  LIST_LAYOUT,
  prependHTTP,
  RegistrationStatus,
  VisitorType,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'
import _ from 'lodash'
import {isEditor, isMobile, isPreview, isSite} from '../../commons/selectors/environment'
import {isEventListPaginationEnabled} from '../../commons/selectors/experiments'
import {
  getListButtonText,
  getOneButtonText,
  getRSVPClosedText,
  getWidgetType,
  isOfferRibbonEnabled,
  isStatusRibbonEnabled,
} from '../../commons/selectors/settings'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {getDetailsTarget} from '../../commons/services/navigation'
import {isRestrictedTo} from '../reducers/members-enabled'
import {State} from '../types/state'
import {isForceShowRibbons} from './layout'
import {getComponentConfig, isOfferRibbonVisible, isRibbonVisible, isStatusRibbonVisible} from './settings'
import {isOwner} from './user'

export const getEventById = (state: State, eventId: string) => getEvents(state).find((event) => event.id === eventId)

export const getEvents = (state: State): ExtendedEvent[] => {
  const demoEvents = (state.demoEvents[getWidgetType(getComponentConfig(state))] as ExtendedEvent[]) || []
  const hasEvents = hasRealEvents(state)

  if (state.siteSettings.firstEventCreated || hasEvents) {
    return (isEditor(state) || isPreview(state)) && !hasEvents ? demoEvents : getRealEvents(state)
  }

  if (!isOwner(state) && isSite(state)) {
    return []
  }

  return demoEvents
}

export const getRealEvents = (state: State) => state.events.events
export const getRealEventsCount = (state: State) => getRealEvents(state).length
export const hasRealEvents = (state: State) => !!getRealEventsCount(state)

export const getFirstEvent = (state: State) => getEvents(state)[0]

export const getEventsIds = (state: State) => getEvents(state).map((event) => event.id)

const RIBBON_TEXTS = {
  [EventType.TICKETS]: {
    [RegistrationStatus.CLOSED]: 'ribbon.soldOut',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.soldOut',
  },
  [EventType.RSVP]: {
    [RegistrationStatus.CLOSED]: 'ribbon.rsvpClosed',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.rsvpClosed',
    [RegistrationStatus.OPEN_RSVP_WAITLIST]: 'ribbon.joinWaitList',
  },
  [EventType.EXTERNAL]: {
    [RegistrationStatus.CLOSED]: 'ribbon.rsvpClosed',
  },
}

const RIBBON_TEXT_FALLBACKS = {
  [EventType.TICKETS]: 'ribbon.soldOut',
  [EventType.RSVP]: 'ribbon.rsvpClosed',
}

export const getRibbonData = (state: State, event: ExtendedEvent) => {
  if (!isRibbonVisible(state, event)) {
    return {visible: false}
  }

  const componentConfig = getComponentConfig(state)
  const mobile = isMobile(state)
  const statusRibbonEnabled = isStatusRibbonEnabled(componentConfig, mobile)
  const offerRibbonEnabled = isOfferRibbonEnabled(componentConfig, mobile)

  if (isForceShowRibbons(state)) {
    if (statusRibbonEnabled) {
      return {
        visible: true,
        text: _.get(RIBBON_TEXT_FALLBACKS, event.registration.type),
        status: true,
        statusVisible: true,
      }
    } else if (offerRibbonEnabled) {
      return {
        visible: true,
        text: 'ribbon.memberOffer',
        status: false,
      }
    }
  }

  const statusText = statusRibbonEnabled
    ? _.get(RIBBON_TEXTS, `${event.registration.type}.${event.registration.status}`)
    : ''
  const offerText = isOfferRibbonVisible(state, event) ? 'ribbon.memberOffer' : ''

  return {
    text: statusText || offerText,
    visible: Boolean(statusText || offerText),
    statusVisible: Boolean(statusText),
    status: Boolean(statusText),
  }
}

export const getRSVPButtonTextForSingleEvent = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return isStatusRibbonVisible(state, event) ? getRSVPClosedText(componentConfig) : getOneButtonText(componentConfig)
}

export const getRSVPButtonTextForEventListItem = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return isStatusRibbonVisible(state, event) ? getRSVPClosedText(componentConfig) : getListButtonText(componentConfig)
}

export const isEventRestricted = (state: State, event: ExtendedEvent) =>
  !isDetailsPageEnabled(state.siteSettings) &&
  isRsvp(event) &&
  isRestrictedTo(event, state, VisitorType.MEMBER) &&
  !state.memberLoggedIn

export const hasEventDetailsUrl = (state: State, event: ExtendedEvent) =>
  isExternal(event) || (isSite(state) && !isEventRestricted(state, event))

export const getEventUrl = (
  state: State,
  event: ExtendedEvent,
  baseUrl: string,
  relativeUrl: string,
  queryParams: string,
) => {
  if (isExternal(event)) {
    return prependHTTP(event.registration.external.registration)
  }
  return getEventDetailsPageUrl(state, event, baseUrl, relativeUrl, queryParams)
}

export const getEventDetailsPageUrl = (
  state: State,
  event: ExtendedEvent,
  baseUrl: string,
  relativeUrl: string,
  queryParams: string,
) => {
  if (isSite(state) && relativeUrl && !isEventRestricted(state, event)) {
    return `${baseUrl}${relativeUrl}/${getDetailsTarget(event, isDetailsPageEnabled(state.siteSettings))}${queryParams}`
  }
  return null
}

export const isLayoutPaginated = (state: State) => {
  if (!isEventListPaginationEnabled(state.experiments)) {
    return false
  }
  const hasStatusFilter = state.component.byStatus?.filter !== undefined
  const {widgetType, listLayout} = state.component.settings
  const hasSupportedLayout = widgetType === WIDGET_TYPE.LIST && listLayout !== LIST_LAYOUT.CALENDAR
  return hasStatusFilter && hasSupportedLayout
}

export const hasMoreEvents = (state: State) => state.events.hasMore
export const isMoreEventsLoading = (state: State) => state.events.moreLoading
